<template>
  <div class="container-fluid p-0 home-bg">
      <div class="container-home">
        <div class="home-title"></div>
        <div class="home-image"></div>
        <div class="start-button">
          <div class="button-standard" style="width: 100%;" @click="start">
            Començar
          </div>
        </div>
          <!-- <img class="home-logo" src="@/assets/images/logo.svg"  alt="Logo">
          <img class="home-logo" src="@/assets/images/home_image.svg"  alt="Logo"> -->
          
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {postData} from '@/services/api'
import VillagesList from '../components/village/VillagesList.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'Home',
    components: {
      VillagesList
    },
    data () {
        return {
          deferredPrompt: null   
        }
    },
    computed: { 
        ...mapGetters({
            isListadoOpen: 'ui/isListadoOpen'
        }) 
    },
    created () {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    methods: {
      start: function () {
        this.$router.push('NewGame')
      },
    }
}
</script>
<style lang="scss" scoped>
.container-home {
  width: 100vw;
  height: 100vh;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-title {
  width: 100%;
  height: 20%;
  background-image: url('~@/assets/images/logo.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.home-image {
  width: 100%;
  flex: 1;
  background-image: url('~@/assets/images/home_image.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.home-logo {
  width: 60%;
  height: auto;
  margin: 40px 0px;
}
.home-welcome-text {
  font-family: $nav-village-font;
  color: $app-color-1;
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid $app-color-1;
}
.start-button {
  padding-bottom: 25%;
}
</style>
