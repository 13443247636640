<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="row nav-content-espai">
          <div class="logo-header"></div>
          <div >
              <a id="navbarDropdown" class="link-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <!-- <img :src="require('@/assets/icons/icon-user.svg')" alt="Profile picture" class="profile-picture"/> -->
                <img src="@/assets/icons/espai/hamburguer-menu.svg" class="d-inline-block nav-user m-0 link-1 hamburger">
              </a>

              <!-- Options pop up -->
              <div class="dropdown-menu dropdown-menu-right dropdown-user" aria-labelledby="navbarDropdown">
                <div class="popup-body">
                  <!-- <div class="dropdown-item modal-call pointer"  data-toggle="modal" data-target="#history" >Instruccions</div>
                  <div class="line-separator"><hr /></div> -->
                  <div class="dropdown-item modal-call pointer" data-toggle="modal" data-target="#management" @click="salir">Sortir</div>
                </div>
            </div>
          </div>
        </div>

    </nav>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'


export default {
  name: "Header",
  components: {

  },
  data() {
    return {
    };
  },
  computed: { 
    ...mapGetters({
    })
  },
  methods:{
    salir: function () {
      this.$store.dispatch('ui/change_players', [])
      localStorage.removeItem('players')
      localStorage.removeItem('punts')
      localStorage.removeItem('current_player')
      localStorage.removeItem('manos')
      this.$router.push('/')
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

.nav-inicio {
    align-content: center;
    display: grid
}

.profile-picture{
    width: 25px;
    padding-right: 5px;
}

.dropdown-user {
  z-index: 10000;
}

.logo-header {
  background-image: url('~@/assets/images/logo-small.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  margin-left: 10%;
  height: 100%;
  width: 40%;
}




</style>
