<template>
  <div class="event-container">
    <div 
        class="event-image-container"
        :style="{
            backgroundImage: 'url(' + require(`@/assets/${player.image}`) + ')'
        }"
    >
    </div>
    <div class="event-text-container">
      <div class="text-nom">{{ player.name }}</div>
      <p>{{ player.mote }}</p>
      <div class="text-punts">{{ punts }} punts</div>
    </div>

    <div       
        class="rank-image-container"
        :style="{
            backgroundImage: 'url(' + require(`@/assets/${rank}`) + ')'
        }"
    >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PlayerCardStandings',
  data () {
    return {

    }
  },
  props: {
    player: Object,
    rank: null,
    punts: null
  },
  computed: { 
    ...mapGetters({

    }) 
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  .event-container {
    height: 100px;
    background-color: #E2D2C3;
    margin: 15px 15px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 20%);
  }
  .event-image-container {
    height: 90px;
    width: 90px;
    background-size: cover;
    background-position: center;
  }
  .rank-image-container {
    height: 90px;
    width: 90px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .event-text-container {
    flex: 1;
    height: 90px;
    margin-left: 15px;
    overflow: hidden;

    small {
      font-size: .7rem;
      }

    p {
      font-size: 0.8rem;
      margin-bottom: 0;
    }
  }
  .text-punts {
    font-size: 1.1rem;
  }
  .text-nom {
    font-size: 1.4rem;
    margin-bottom: 0px;
  }
</style>
