import { render, staticRenderFns } from "./TaulaPunts.vue?vue&type=template&id=5f792516&scoped=true&"
import script from "./TaulaPunts.vue?vue&type=script&lang=js&"
export * from "./TaulaPunts.vue?vue&type=script&lang=js&"
import style0 from "./TaulaPunts.vue?vue&type=style&index=0&id=5f792516&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f792516",
  null
  
)

export default component.exports