<template>
  <div class="container-fluid p-0 home-bg" style="display: flex; flex-direction: column;">
    <Header></Header>
    <div class="white-text" style="width: 100%; display: flex; align-items: center; justify-content: center; margin-top: 20px;"><h4>Seleccionar jugadors</h4></div>
     
    <div class="nou-modal">
      <input type="text" placeholder="Nom" name="nom" id="nom" v-model="nounom" />
      <input type="text" placeholder="Més conegut com" name="aka" id="aka" v-model="nouaka" />
    </div>
    <div style="width: 100%; display: flex; align-items: center; justify-content: center; margin-top: 10px; padding: 10 px;">
      <!-- <select v-model="selected_player" style="margin-left: 10px; height: 70%">
        <option disabled value="">Seleccionar un jugador</option>
        <option value="nou">Nou jugador</option>
        <option value="anna">Anna</option>
        <option value="pol">Pol</option>
        <option value="espe">Espe</option>
        <option value="manel">Manel</option>
        <option value="pedro">Pedro</option>
        <option value="pau">Pau</option>
      </select> -->
      <div class="button-standard" style="width: 100%; border-radius: 5px;" @click="addPlayer">
        Afegir
      </div>
    </div>

    <!-- <div v-if="selected_player == 'nou'" class="nou-modal"> -->
    <div class="separator-green"></div>

    <div class="players-container">
      <PlayerCard v-for="(player, index) in players" v-bind:key="index" :player="player" :order="index + 1"></PlayerCard>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Components
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import PlayerCard from '@/components/PlayerCard.vue'

export default {
  name: 'NewGame',
  components: {
    Header,
    Footer,
    PlayerCard
  },
  created () {
    this.$store.dispatch('ui/change_current_interface', 1)
    localStorage.setItem("current_interface", 1)

    const storedPlayers = localStorage.getItem("players")
    if(storedPlayers) {
      this.temp_players = JSON.parse(storedPlayers)
      this.players = JSON.parse(storedPlayers)
    }
  },
  computed: { 
    ...mapGetters({
    }),
    players: {
      set (data) { this.$store.dispatch('ui/change_players', data); },
      get () { return this.$store.state.ui.players }
    }, 
  },
  data () {
    return {
      selected_player: '',
      village_url: '',
      temp_players: [],
      nounom: '',
      nouaka: ''
    }
  },
  mounted () {
  },
  methods: {
    addPlayer: function () {
      // if(this.selected_player == 'anna') {
      //   let player = {}
      //   player.name = 'Anna'
      //   player.image = 'images/user_icon.svg'
      //   player.mote = "La guapita"
      //   this.temp_players.push(player)
      // }
      // else if(this.selected_player == 'pol') {
      //   let player = {}
      //   player.name = 'Pol'
      //   player.image = 'images/user_icon.svg'
      //   player.mote = "El sex symbol"
      //   this.temp_players.push(player)
      // }
      // else if(this.selected_player == 'espe') {
      //   let player = {}
      //   player.name = 'Espe'
      //   player.image = 'images/user_icon.svg'
      //   player.mote = "La mama"
      //   this.temp_players.push(player)
      // }
      // else if(this.selected_player == 'manel') {
      //   let player = {}
      //   player.name = 'Manel'
      //   player.image = 'images/user_icon.svg'
      //   player.mote = "El papa"
      //   this.temp_players.push(player)
      // }
      // else if(this.selected_player == 'pedro') {
      //   let player = {}
      //   player.name = 'Pedro'
      //   player.image = 'images/user_icon.svg'
      //   player.mote = "The new papa"
      //   this.temp_players.push(player)
      // }
      // else if(this.selected_player == 'pau') {
      //   let player = {}
      //   player.name = 'Pau'
      //   player.image = 'images/user_icon.svg'
      //   player.mote = "Available soon..."
      //   this.temp_players.push(player)
      // }
      // else if(this.selected_player == 'nou' && this.nounom != '') {
      if(this.nounom != '') {
        let player = {}
        player.name = this.nounom
        player.image = 'images/user_icon.svg'
        player.mote = this.nouaka
        this.temp_players.push(player)

        this.players = this.temp_players
        localStorage.setItem("players", JSON.stringify(this.temp_players))

        this.nounom = ''
        this.nouaka = ''
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.separator-green {
  border-bottom: 2px solid #26814f;
}
.players-container {
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 50px;
}
.nou-modal {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0px 2px 3px 5px rgba(0,0,0,0.3);
  margin-bottom: 10px;
}

input {
  margin: 5px 0px;
}
</style>
