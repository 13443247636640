<template>
  <div style="padding-bottom: 30px;">
    <table>
      <tr v-for="(mano, indexmano) in manos" v-bind:key="indexmano">
        <td v-if="indexmano + 1 <= max_cards">{{indexmano + 1}}</td>
        <td v-else>{{max_cards - (indexmano + 1 - max_cards)}}</td>
        <td v-for="(player, indexplayer) in players" v-bind:key="indexplayer">
          <div class="interior-celda" v-bind:class="{current_player_history: mano[indexplayer][2] == indexplayer}">
            <span class="resultado res-good" v-if="mano[indexplayer][0] == mano[indexplayer][1]">{{ mano[indexplayer][1] }}</span>
            <span class="resultado res-bad" v-else>{{ mano[indexplayer][1] }}</span>
            <span class="apuesta">{{ mano[indexplayer][0] }}</span>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="manos.length < ((max_cards * 2) - 1)">
        <tr v-if="fase==1"><td :colspan="players.length +1" style="padding: 10px;">Quantes te'n faràs?</td></tr>
        <tr v-else><td :colspan="players.length +1" style="padding: 10px;">Quantes te n'has fet?</td></tr>
        <tr>
          <td  v-if="manos.length + 1 <= max_cards">{{manos.length + 1}}</td>
          <td v-else>{{max_cards - (manos.length + 1 - max_cards)}}</td>
          <td v-for="(player, indexplayer) in players" v-bind:key="indexplayer">
            <div class="interior-celda" v-bind:class="{current_player_class: current_player == indexplayer}">
              <span>
                <!-- <select @change="onChangeSelect($event, indexplayer)" v-model="select_values['player'+indexplayer]"> -->
                <select v-model="select_values['player'+indexplayer]">
                  <option value="0" selected>0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </span>
              <span v-if="fase==2" class="apuesta">{{current_mano[indexplayer][0]}}</span>
            </div>
          </td>
        </tr>
    </table>

    <div @click="changeFase()" class="nav-button" v-if="manos.length < ((max_cards * 2) - 1)" style="margin-top: 8px;">
        OK
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TaulaPunts',
  props: {
    first_player: null,
  },
  data () {
    return {
      manos: [],
      fase: 1,
      current_mano: [],
      select_values: {},
      max_cards: 11,
      current_player: 0,
      no_pots_dir: 0
    }
  },
  computed: { 
    ...mapGetters({
      current_interface: 'ui/current_interface',
      players: 'ui/players',
    }),
    punts: {
      set (data) { this.$store.dispatch('ui/change_punts', data); },
      get () { return this.$store.state.ui.punts }
    }, 
  },
  watch: {
    select_values: {
      handler(val){
        this.set_no_pods_dir()
      },
      deep: true
    }
  },
  mounted () {
    const storedManos = localStorage.getItem("manos")
    if (storedManos) {
      this.manos = JSON.parse(storedManos)
    }
    
    this.resetSelects()
    this.players.forEach(player => {
      this.current_mano.push([0,0])
    })
    if(this.players.length == 5) {
      this.max_cards = 10
    }
    else if (this.players.length == 6) {
      this.max_cards = 8
    }

    const storedCurrentPlayer = localStorage.getItem("current_player")
    if(storedCurrentPlayer) {
      this.current_player = storedCurrentPlayer
    }
    else {
      this.setCurrentPlayer()
    }  
  },
  methods: {
    onChangeSelect(event, indexplayer) {
      this.current_mano[indexplayer][this.fase - 1] = event.target.value
    },
    changeFase() {
      console.log(this.fase)
      for(let i=0; i<this.players.length; i++) {
        this.current_mano[i][this.fase - 1] = this.select_values['player'+i]
      }
      if(this.fase == 1) {
        this.fase = '2'  
      }
      else if(this.fase == 2) {
        this.manos.push([])
        for(let i=0; i<this.players.length; i++) {
          this.manos[this.manos.length-1].push([this.current_mano[i][0], this.current_mano[i][1], this.current_player])
        }
        localStorage.setItem("manos", JSON.stringify(this.manos))

        this.points()
        this.fase = 1
        this.setCurrentPlayer()
      }
      this.resetSelects()
    },
    resetSelects() {
      for(let i=0; i<this.players.length; i++) {
        this.select_values['player'+i] = 0
      }
    },
    setCurrentPlayer() {
      if (this.manos.length == 0) {
        if(this.first_player == 0) {
          this.current_player = this.players.length - 1
        }
        else {
          this.current_player = this.first_player - 1
        }
      }
      else if (this.current_player == this.players.length - 1) {
        this.current_player = 0
      }
      else {
        this.current_player ++
      }
      localStorage.setItem("current_player", this.current_player)
    },
    points() {
      let localpunts = []
      for(let j=0; j<this.players.length; j++) {
        localpunts[j] = 0
      }
      for(let i=0; i<this.manos.length; i++) {
        for(let j=0; j<this.players.length; j++) {
          if (this.manos[i][j][0] == this.manos[i][j][1]) {
            localpunts[j] = localpunts[j] + 10 + (3 * this.manos[i][j][0])
          }
          else {
            localpunts[j] = localpunts[j] - (3 * Math.abs(this.manos[i][j][0] - this.manos[i][j][1]))
          }
        }  
      }
      this.punts = localpunts
      localStorage.setItem("punts", JSON.stringify(localpunts))
    },
    set_no_pods_dir() {
      console.log("No pots!")
      let sumatorio_apuestas = 0
      for(let i=0; i<this.players.length; i++) {
        sumatorio_apuestas += this.select_values['player'+i]
      }
      this.no_pots_dir = sumatorio_apuestas
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  table {
    width: 100%;
    table-layout: fixed;
  }
  th {
    background-color: #E2D2C3;
    padding: 8px;
    border: 1px solid #aa9989;
    text-align: center;
  }
  td {
    background-color: #E2D2C3;
    border: 1px solid #aa9989;
    text-align: center;
  }
  .interior-celda {
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
  }
  .apuesta {
    background-color: #c8b6a4;
    height: 100%;
    padding: 5px;
  }
  .resultado {
    flex: 1;
    height: 100%;
    padding: 5px;
  }
  .res-good {
    background-color: #9bdc9b;
  }
  .res-bad {
    background-color: #ff000042;
  }
  .current_player_class {
    background-color: rgb(255, 174, 0);
    border: 2px solid rgb(255, 174, 0);
  }
  .current_player_history {
    border: 2px solid rgb(255, 174, 0);
  }
  .scoreboard {
    position: fixed;
    /* top: 0; */
    z-index: 10;
  }

  .nav-button {
    height: 40px;
    background-color: #EDE2D9;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.3);
    border-radius: 5px;
    padding: 12px;
    color: black;
    width: 80%;
    margin-left: 10%;
    box-shadow: 0px 2px 3px 3px rgba(0,0,0,0.3);
  }



  .event-container {
    height: 100px;
    background-color: #E2D2C3;
    margin: 15px 15px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 20%);
  }
  .event-image-container {
    height: 90px;
    width: 90px;
    background-color: pink;
    background-size: cover;
    background-position: center;
  }
  .event-text-container {
    flex: 1;
    height: 90px;
    margin-left: 15px;
    overflow: hidden;

    div { 
      font-size: 1.4rem;
      margin-bottom: 0px;
    }

    small {
      font-size: .7rem;
      }

    p {
      font-size: 0.8rem;
    }
  }
</style>
