const state = () => ({
  status: 1, 
  current_interface: 1, // 1.- Qui comença / 2.- Taula de punts / 3.- Standings
  current_event: null,
  isListadoOpen: false,
  players: [],
  punts: null
})

const mutations = {
  set_status(state, data){
    state.status = data
  },
  set_current_interface(state, data){
    state.current_interface = data
  },
  set_current_event(state, data){
    state.current_event = data
  },
  set_listado_status(state, data){
    state.isListadoOpen = data
  },
  set_players(state, data){
    state.players = data.slice()
  },
  set_punts(state, data){
    state.punts = data.slice()
  },
}

const actions = {
  change_status({ commit }, status) {
    commit("set_status", status)
  },
  change_current_interface({ commit }, current_interface) {
    commit("set_current_interface", current_interface)
  },
  change_current_event({ commit }, current_event) {
    commit("set_current_event", current_event)
  },
  change_listado_status({ commit }, isListadoOpen) {
    commit("set_listado_status", isListadoOpen)
  },
  change_players({ commit }, players) {
    commit("set_players", players)
  },
  change_punts({ commit }, punts) {
    commit("set_punts", punts)
  }
}

const getters = {
    status: state => state.status,
    current_interface: state => state.current_interface,
    current_event: state => state.current_event,
    isListadoOpen: state => state.isListadoOpen,
    players: state => state.players,
    punts: state => state.punts,
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }