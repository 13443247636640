<template>
  <footer>
    <div class="footer-content">
      <div @click="start()" class="nav-button" v-show="$route.name=='NewGame'">
        <img src="@/assets/images/burning_card.svg"  alt="Separador foto">
        Començar joc!
      </div>
      <div @click="goTo(2)" class="nav-button" v-show="$route.name=='Game' && current_interface == 1">
        <img src="@/assets/images/burning_card.svg"  alt="Separador foto">
        Ara sí, Començar joc!
      </div>
      <div @click="goTo(2)" class="nav-button fixed-width" v-show="$route.name=='Game' && current_interface != 1">
        Taula
      </div>
      <div @click="goTo(3)" class="nav-button fixed-width" v-show="$route.name=='Game' && current_interface != 1">
        Classificació
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Footer',
  data () {
    return {
    }
  },
  computed: { 
    ...mapGetters({
      current_interface: 'ui/current_interface',
      players: 'ui/players',
    }),
  },
  methods: {
    goTo: function (location) {
      this.$store.dispatch('ui/change_current_interface', location)
      localStorage.setItem("current_interface", location)
    },
    start: function () {
      if(this.players.length >= 2){
        this.$router.push('Game')
      }
      else {
        alert ("Fan falta jugadors!")
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $app-color-2;
    color: $app-color-1;
    padding: 0.2rem 0.2rem 0.2rem 0.5rem;
    /* margin-top: 20px; */
    box-shadow: 0px -2px 3px 2px rgba(0,0,0,0.2);
    z-index: 99999;
  }

  .footer-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    img { 
      height: 30px;
    }
  }

  .nav-button {
    height: 40px;
    background-color: #EDE2D9;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.3);
    border-radius: 5px;
    padding: 12px;
    color: black;
  }
  .fixed-width {
    width: 120px;
  }
</style>
