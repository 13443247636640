<template>
  <div class="container-fluid p-0 home-bg" style="display: flex; flex-direction: column;">
    <Header></Header>
    <div v-if="current_interface == 1">
      <div class="white-text" style="width: 100%; display: flex; align-items: center; justify-content: center; margin-top: 20px;"><h4>Qui comença? (Carta més alta)</h4></div>

      <div class="separator-green"></div>
    </div>


    <!-- SCOREBOARD -->
    <table class="scoreboard" v-if="current_interface==2">
      <tr>
        <th></th>
        <th v-for="(player, index) in players" v-bind:key="index">{{player.name}}</th>
      </tr>
      <tr v-if="punts">
        <td>Punts</td>
        <td v-for="(player, indexplayer) in players" v-bind:key="indexplayer">{{punts[indexplayer]}}</td>
      </tr>
    </table>

    <div class="players-container">
      <div v-if="current_interface == 1">
        <div v-for="(player, index) in players" v-bind:key="index" @click="selectPlayer(index)">
          <PlayerCard :player="player" :order="index + 1" v-if="index == first_player"></PlayerCard>
          <PlayerCard :player="player" :order="index + 1" v-else style="opacity: 0.3;"></PlayerCard>
        </div>
      </div>

      <TaulaPunts v-if="current_interface>=2" :first_player="first_player" v-bind:class="{ 'table-hidden': current_interface==3 }"></TaulaPunts>
      <Standings v-if="current_interface==3"></Standings>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Components
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import PlayerCard from '@/components/PlayerCard.vue'
import TaulaPunts from '@/components/TaulaPunts.vue'
import Standings from '@/components/Standings.vue'

export default {
  name: 'Game',
  components: {
    Header,
    Footer,
    PlayerCard,
    TaulaPunts,
    Standings
  },
  created () {
    if(this.players.length == 0) {
      const storedPlayers = localStorage.getItem("players")
      if(storedPlayers) {
        this.$store.dispatch('ui/change_players', JSON.parse(storedPlayers))
      }
      else {
        this.$router.push('/')
      }
    }

    const storedCurrentInterface = localStorage.getItem("current_interface")
    if(storedCurrentInterface && storedCurrentInterface > 1) {
      this.$store.dispatch('ui/change_current_interface', 2)
    }
  },
  computed: { 
    ...mapGetters({
        current_interface: 'ui/current_interface',
        players: 'ui/players',
        punts: 'ui/punts'
    }) 
  },
  data () {
    return {
      first_player: 0
    }
  },
  mounted () {
    const storedPunts = localStorage.getItem("punts")
    if (storedPunts) {
      this.$store.dispatch('ui/change_punts', JSON.parse(storedPunts))
    }
    else {
      let localpunts = []
      for(let j=0; j<this.players.length; j++) {
        localpunts[j] = 0
      }
      this.$store.dispatch('ui/change_punts', localpunts)
      localStorage.setItem("punts", JSON.stringify(localpunts))
    }
  },
  methods: {
    selectPlayer: function (index) {
      this.first_player = index
    }
  }
}
</script>

<style lang="scss" scoped>
.separator-green {
  border-bottom: 2px solid #26814f;
}
.players-container {
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 50px;
}

table {
  width: 100%;
  table-layout: fixed;
  box-shadow: 0px 2px 3px 3px rgba(0,0,0,0.3);
  z-index: 10
}
th {
  background-color: #E2D2C3;
  padding: 8px;
  border: 1px solid #aa9989;
  text-align: center;
}
td {
  background-color: #E2D2C3;
  border: 1px solid #aa9989;
  text-align: center;
}
.table-hidden {
  display: none;
}
</style>
