import { getData, postData } from "@/services/api";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

Vue.use(VueToast);

const state = () => ({
  village: {},
  main_image_url: null,
  flag_image_url: null
});

const mutations = {
  set_village(state, data) {
    state.village = data
  },
  set_main_image_url(state, data) {
    state.main_image_url = data
  },
  set_flag_image_url(state, data) {
    state.flag_image_url = data
  }
};

const actions = {
  change_village({ commit }, data) {
    commit('set_village', data)
  },
  change_main_image_url({ commit }, data) {
    commit('set_main_image_url', data)
  },
  change_flag_image_url({ commit }, data) {
    commit('set_flag_image_url', data)
  }
};

const getters = {
  village: (state) => state.village,
  main_image_url: (state) => state.main_image_url,
  flag_image_url: (state) => state.flag_image_url,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  VueToast,
};
