<template>
  <div style='position: absolute; left: 50%; top: 20%;'>
    <div class='villages_list_container'>
    <div class='btn cerrar-btn' @click="closeListado">X</div>
        
    <div v-if="this.villages_list.length > 0" class='village_list'>
        <div v-for="(village, index) in villages_list" v-bind:key="index">
            <div class="village-card" @click='goTo(village.nameurl)'>
                <h6>{{village.name}}</h6>
                <p>{{village.description }}</p>
            </div>
        </div>
    </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getData} from '@/services/api'


export default {
    name: 'VillagesList',
    components: {
        
    },
    data () {
        return {
            villages_list: [],
        }
    },
    computed: { 
        ...mapGetters({
            isListadoOpen: 'ui/isListadoOpen'
        }) 
    },
    mounted () {
        this.getVillages()
    },
    methods: {
        getVillages: function () {
        getData('villages')
        .then (response => {
            console.log(response.data.villages)
            if(response.data.villages.length == 0) {
            this.closeListado()
            alert('No tenemos ningun pueblo todavia')
            }
            else {
            this.villages_list = response.data.villages
            console.log(this.villages_list, 'soy la village')
            }
        })
        }, 
        closeListado: function () {
            this.$store.dispatch('ui/change_listado_status', false)
        },
        goTo: function (url) {
            this.$router.push('/'+url)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

    .villages_list_container {
    width: 350px;
    height: 400px;
    position: relative;
    left: -50%;
    border-radius: 5px;
    align-items: center;
    background-color: #e2d2c3;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 20%);
    z-index: 99999;
    padding: 25px 5px;
    }

    .village-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    align-items: center;
    background-color: #e2d2c3;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 20%);
    }

    .cerrar-btn {
        position: absolute;
        z-index: 99999;
        top: 0;
        right: 0;
    }
    

</style>