<template>
  <div class="event-container">
    <div 
        class="event-image-container"
        :style="{
            backgroundImage: 'url(' + require(`@/assets/${player.image}`) + ')'
        }"
    >
    </div>
    <div class="event-text-container">
      <small>{{ order }}</small>
      <div class="event-title">{{ player.name }}</div>
      <p>{{ player.mote }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PlayerCard',
  data () {
    return {

    }
  },
  props: {
    player: Object,
    order: null
  },
  computed: { 
    ...mapGetters({

    }) 
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  .event-container {
    height: 100px;
    background-color: #E2D2C3;
    margin: 15px 15px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 20%);
  }
  .event-image-container {
    height: 90px;
    width: 90px;
    background-size: cover;
    background-position: center;
  }
  .event-text-container {
    flex: 1;
    height: 90px;
    margin-left: 15px;
    overflow: hidden;

    div { 
      font-size: 1.4rem;
      margin-bottom: 0px;
    }

    small {
      font-size: .7rem;
      }

    p {
      font-size: 0.8rem;
    }
  }
</style>
