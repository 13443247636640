<template>
  <div>
    <div class="white-text" style="width: 100%; display: flex; align-items: center; justify-content: center; margin-top: 20px;"><h4>Classificació</h4></div>
    <div class="players-container">

      <div>
        <div v-for="(player, indexgold) in players" v-bind:key="indexgold">
          <div v-if="arrayStandings[indexgold]=='oro'">
            <PlayerCardStandings :player="player" rank="images/medal_gold.svg" :punts="punts[indexgold]"></PlayerCardStandings>
          </div>
        </div>
      </div>

      <div>
        <div v-for="(player, indexsilver) in players" v-bind:key="indexsilver">
          <div v-if="arrayStandings[indexsilver]=='plata'">
            <PlayerCardStandings :player="player" rank="images/medal_silver.svg" :punts="punts[indexsilver]"></PlayerCardStandings>
          </div>
        </div>
      </div>

      <div>
        <div v-for="(player, indexbronze) in players" v-bind:key="indexbronze">
          <div v-if="arrayStandings[indexbronze]=='bronce'">
            <PlayerCardStandings :player="player" rank="images/medal_bronze.svg" :punts="punts[indexbronze]"></PlayerCardStandings>
          </div>
        </div>
      </div>
      
      <div>
        <div v-for="(player, indexchoco) in players" v-bind:key="indexchoco">
          <div v-if="arrayStandings[indexchoco]=='chocolate'">
            <PlayerCardStandings :player="player" rank="images/medal_chocolate.svg" :punts="punts[indexchoco]"></PlayerCardStandings>
          </div>
        </div>
      </div>

      <div>
        <div v-for="(player, indexloser) in players" v-bind:key="indexloser">
          <div v-if="arrayStandings[indexloser]=='loser'">
            <PlayerCardStandings :player="player" rank="images/medal_loser.svg" :punts="punts[indexloser]"></PlayerCardStandings>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Components
import PlayerCardStandings from '@/components/PlayerCardStandings.vue'

export default {
  name: 'Standings',
  components: {
    PlayerCardStandings
  },
  created () {
  },
  computed: { 
    ...mapGetters({
        players: 'ui/players',
        punts: 'ui/punts'
    }),
  },
  data () {
    return {
      arrayStandings: []
    }
  },
  mounted () {
    this.setRank()
  },
  methods: {
    setRank() {
      for(let i=0; i<this.players.length; i++) {
        let betterThan = 0
        for(let j=0; j<this.punts.length; j++) {
          if (this.punts[i] >= this.punts[j]) {
            betterThan++
          }
        }
        console.log(this.players[i].name + " is better than " + betterThan)
        if(betterThan == this.players.length) {
          this.arrayStandings.push('oro')
        }
        else if(betterThan == this.players.length - 1) {
          this.arrayStandings.push('plata')
        }
        else if(betterThan == this.players.length - 2) {
          this.arrayStandings.push('bronce')
        }
        else if(betterThan == this.players.length - 3) {
          this.arrayStandings.push('chocolate')
        }
        else {
          this.arrayStandings.push('loser')
        }
      }

      // if (!this.arrayStandings.includes('plata')){
      //   for(let i=0; i<this.arrayStandings.length; i++) {
      //     if() {

      //     }
      //   }
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.separator-green {
  border-bottom: 2px solid #26814f;
}
.players-container {
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 50px;
}
</style>
